import React, { ReactNode } from 'react';
import styled from '@emotion/styled';
import { ITheme, themed } from '@m2/blueprint-ui';

interface IAccordionProps {
	children: ReactNode;
	className?: string;
}

const StyledAccordion = styled('div')<{}, ITheme>`
	border-top: ${themed(({ accordion }) => accordion.border)};
`;

export const Accordion = ({ children, className }: IAccordionProps) => (
	<StyledAccordion className={className}>{children}</StyledAccordion>
);
